<template>
	<el-dialog
		:title="titleDialog"
		:visible="showDialog"
		@close="close"
		@open="create"
		append-to-body
		width="55%"
	>
		<form autocomplete="off" @submit.prevent="submit">
			<div class="form-body">
				<div class="row">
					<div class="col-xl-4">
						<div class="form-group" :class="{ 'has-danger': errors.type }">
							<label class="control-label">Tipo</label>
							<el-select v-model="form.type" @change="changeType">
								<el-option
									v-for="option in types"
									:key="option.type"
									:value="option.type"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="form-control-feedback"
								v-if="errors.type"
								v-text="errors.type[0]"
							></small>
						</div>
					</div>
					<div class="col-xl-8">
						<div class="form-group" :class="{ 'text-danger': errors.operation_id }">
							<label class="control-label">Operación</label>
							<el-select v-model="form.operation_id" filterable dusk="operation_id">
								<el-option
									v-for="option in operations"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="form-control-feedback"
								v-if="errors.operation_id"
								v-text="errors.operation_id[0]"
							></small>
						</div>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-xl-4">
						<div class="form-group" :class="{ 'text-danger': errors.amount }">
							<label class="control-label"
								>Monto <span class="text-danger">*</span></label
							>
							<el-input
								v-model="form.amount"
								dusk="amount"
								class="input-text-right"
							></el-input>
							<small
								class="form-control-feedback"
								v-if="errors.amount"
								v-text="errors.amount[0]"
							></small>
						</div>
					</div>
					<div class="col-xl-8">
						<div class="form-group" :class="{ 'text-danger': errors.reference }">
							<label class="control-label">Referencia</label>
							<el-input v-model="form.reference" dusk="reference"></el-input>
							<small
								class="form-control-feedback"
								v-if="errors.reference"
								v-text="errors.reference[0]"
							></small>
						</div>
					</div>
				</div>
			</div>
			<v-row>
				<v-col cols="12" xl="12">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading_submit">
						<template v-if="loading_submit">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
import { confirm } from 'Mixins/confirm';
import filter from 'lodash/filter';
import dayjs from 'dayjs';

export default {
	props: ['showDialog', 'recordId', 'finalBalance'],
	mixins: [confirm],
	data() {
		return {
			loading_submit: false,
			titleDialog: null,
			resource: 'transactions',
			errors: {},
			form: {},
			types: [],
			operations: [],
			all_operations: [],
		};
	},
	created() {
		//
	},
	// beforeDestroy() {
	// 	this.$eventHub.destroy('reloadData');
	// },
	methods: {
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.types = response.data.types;
				this.all_operations = response.data.operations;

				this.form.type = this.types.length > 0 ? this.types[0].type : null;
				this.changeType();
			});
		},
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				opening_id: this.recordId,
				operation_id: null,
				date_of_trx: dayjs().format('YYYY-MM-DD'),
				amount: 0,
				type: null,
				reference: null,
				confirmed: true,
			};
		},
		async create() {
			this.titleDialog = this.cashMovId ? 'Editar movimiento' : 'Nuevo movimiento';
			await this.initForm();
			await this.getTables();
		},
		changeType() {
			this.form.operation_id = null;
			this.operations = filter(this.all_operations, { type: this.form.type });
		},
		submit() {
			if (
				this.form.type === 'expense' &&
				Number(this.form.amount) > Number(this.finalBalance)
			) {
				return this.$message.error(
					'Error, No hay saldo suficiente para realizar la operación.'
				);
			}

			this.confirm().then(() => {
				this.loading_submit = true;
				this.$http
					.post(`/${this.resource}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							this.$eventHub.$emit('reloadData', 'openings');
							this.close();
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loading_submit = false;
					});
			});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
