<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			append-to-body
			width="30%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row dense>
					<v-col cols="12" xl="12" lg="12" md="12">
						<div class="form-group" :class="{ 'error--text': errors.shift_id }">
							<label class="control-label">Turno</label>
							<el-select v-model="form.shift_id" dusk="shift_id">
								<el-option
									v-for="option in shifts"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.shift_id"
								v-text="errors.shift_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12" lg="12" md="12">
						<div class="form-group" :class="{ 'error--text': errors.cash_id }">
							<label class="control-label">Caja</label>
							<el-select v-model="form.cash_id" dusk="cash_id">
								<el-option
									v-for="option in cashes"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.cash_id"
								v-text="errors.cash_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12">
						<div
							class="form-group"
							:class="{
								'error--text': errors.beginning_balance,
								'error--text': errors.date_opening,
							}"
						>
							<label>Monto Inicial</label>
							<el-input-number
								v-model="form.beginning_balance"
								controls-position="right"
								:precision="2"
							></el-input-number>
							<small
								class="error--text"
								v-if="errors.beginning_balance"
								v-text="errors.beginning_balance[0]"
							></small>
							<small
								class="error--text"
								v-if="errors.date_opening"
								v-text="errors.date_opening[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<el-button type="danger" plain @click.prevent="close()">Cancelar</el-button>
						<el-button type="primary" native-type="submit" :loading="loading">
							<template v-if="loading">
								Aperturando...
							</template>
							<template v-else>
								Aperturar
							</template>
						</el-button>
					</v-col>
				</v-row>
			</form>
		</el-dialog>
	</div>
</template>

<script>
import { confirm } from 'Mixins/confirm';
import dayjs from 'dayjs';

export default {
	props: ['showDialog'],
	mixins: [confirm],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'openings',
			shifts: [],
			cashes: [],
			errors: {},
			form: {},
		};
	},
	// beforeDestroy() {
	// 	this.$eventHub.destroy('reloadData');
	// },
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				cash_id: null,
				date_opening: dayjs().format('YYYY-MM-DD'),
				beginning_balance: 0,
			};
		},
		async create() {
			await this.initForm();
			await this.getTables();
			this.titleDialog = 'Aperturar caja';
			if (this.recordId) {
				this.$http.get(`/${this.resource}/record/${this.recordId}`).then((response) => {
					this.form.cash_id = response.data.data.id;
				});
			}
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.cashes = response.data.cashes;
				this.shifts = response.data.shifts;

				this.form.cash_id = this.cashes.length > 0 ? this.cashes[0].id : null;
			});
		},
		submit() {
			this.confirm().then(() => {
				this.loading = true;
				this.$http
					.post(`/${this.resource}/open`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							this.$eventHub.$emit('reloadData', this.resource);
							this.close();
							// location.reload();
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loading = false;
					});
			});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
