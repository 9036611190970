<template>
	<div v-if="loaded">
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h6 v-if="cash && !last_opened">
					<span>
						{{ cash.cash.name }} -|- Turno: {{ cash.shift.short }}  -|- 
						<v-icon>mdi-calendar-today</v-icon> {{ date_opening }} -|-
						<v-icon>mdi-timer</v-icon> {{ cash.time_opening }}</span
					>
				</h6>
				<h6 v-if="!cash && last_opened">
					<span
						>{{ last_opened.cash.name }} -|- Monto Inicial:
						{{ last_opened.beginning_balance }} -|- <v-icon>mdi-calendar-today</v-icon>
						{{ date_opening }} -|- <v-icon>mdi-timer</v-icon>
						{{ last_opened.time_opening }}</span
					>
				</h6>

				<h6 v-if="!cash && !last_opened"><span>Apertura de caja</span></h6>
			</div>
			<div class="action-btn-wrap">
				<template v-if="cash && !last_opened">
					<!-- <v-btn color="secondary" class="mr-1" @click.prevent="openTrxDialog()">
						<v-icon left>mdi-swap-horizontal</v-icon> Nuevo movimiento
					</v-btn> -->
					<v-btn color="accent" @click.prevent="clickCloseCash()">
						<v-icon left small>mdi-lock</v-icon> Cerrar caja
					</v-btn>
				</template>
				<template v-if="!cash && last_opened">
					<v-btn color="accent" @click.prevent="clickCloseCash()">
						<v-icon left small>mdi-lock</v-icon> Cerrar caja
					</v-btn>
				</template>
				<template v-if="!cash && !last_opened">
					<v-btn color="accent" @click.prevent="clickOpenCash()">
						<v-icon left small>mdi-lock-open</v-icon> Apertura de caja
					</v-btn>
				</template>
			</div>
		</div>
		<template>
			<v-card color="secondary" class="pa-3">
				<v-row dense>
					<v-col cols="12" xl="9" md="9" sm="9">
						<v-simple-table fixed-header dense height="400px">
							<template v-slot:default>
								<thead>
									<tr>
										<th colspan="11" class="text-center">ALQUILERES</th>
									</tr>
									<tr>
										<th class="text-center">HAB.</th>
										<th class="text-center">T</th>
										<th>CLIENTE</th>
										<!-- <th>PROCEDENCIA</th> -->
										<th class="text-center">FECHA/HORA INGRESO</th>
										<th class="text-center">B/F</th>
										<th class="text-center">FECHA/HORA SALIDA</th>
										<th class="text-right">N° D</th>
										<th class="text-right">N° P</th>
										<th class="text-right">PRECIO</th>
										<th class="text-right">OBSERVACIONES</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, index) in record_rents" :key="index">
										<td scope="row" class="text-center">{{ row.room }}</td>
										<td scope="row" class="text-center">{{ row.room_type }}</td>
										<td scope="row">
											{{ row.customer }}
										</td>
										<!-- <td scope="row">{{ row.customer_address }}</td> -->
										<td scope="row" class="text-center">{{ row.date_of_entry }}</td>
										<td scope="row" class="text-center">{{ row.document }}</td>
										<td scope="row" class="text-center">{{ row.date_of_exit +' '+row.time_of_exit }}</td>
										<td class="text-right">
											{{ (row.period_id === '01') ? 0 : row.number_of_days }}
										</td>
										<td class="text-right">
											{{ row.number_of_people }}
										</td>
										<td class="text-right">
											<span class="text-success">{{ row.total }}</span>
										</td>
										<td>
											<span>{{ row.observations }}</span>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
					<v-col cols="12" xl="3" md="3" sm="3">
						<v-simple-table fixed-header dense height="400px">
							<template v-slot:default>
								<thead>
									<tr>
										<th colspan="4" class="text-center">PRODUCTOS</th>
									</tr>
									<tr>
										<th class="text-center">CANT.</th>
										<th>PRODUCTO</th>
										<th class="text-right">TOTAL</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, index) in record_items" :key="index">
										<td scope="row" class="text-center">{{ row.quantity }}</td>
										<td scope="row">{{ row.name }}</td>
										<td class="text-right">
											<span class="text-success">{{ row.total }}</span>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
			</v-card>

			<v-row>
				<v-col cols="12" xl="6" lg="10" offset-xl="6" offset-lg="2">
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr>
									<td scope="row" class="text-right">Saldo Inicial:</td>
									<td class="text-right">
										{{
											cash
												? cash.beginning_balance
												: last_opened
												? last_opened.beginning_balance
												: 0
										}}
									</td>
								</tr>
								<tr>
									<td scope="row" class="text-right">Total Alquiler:</td>
									<td class="text-right">{{ totals.total_rent }}</td>
								</tr>
								<tr>
									<td scope="row" class="text-right">Total Productos:</td>
									<td class="text-right">{{ totals.total_items }}</td>
								</tr>
								<tr>
									<td scope="row" class="text-right">
										<strong>Saldo final:</strong>
									</td>
									<td class="text-right">
										<strong>{{
											totals.final_balance_frt ? totals.final_balance_frt : 0
										}}</strong>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
		</template>

		<open-cash-form :showDialog.sync="showDialog"> </open-cash-form>

		<trx-form
			:showDialog.sync="showTrxFormDialog"
			:recordId="recordId"
			:finalBalance="finalBalance"
		>
		</trx-form>
	</div>
</template>

<script>
import { confirm } from 'Mixins/confirm';
import OpenCashForm from './Open';
import TrxForm from './Trx';
import dayjs from 'dayjs';

export default {
	mixins: [confirm],
	data() {
		return {
			showDialog: false,
			showTrxFormDialog: false,
			resource: 'openings',
			recordId: null,
			record_rents: [],
			record_items: [],
			cash: null,
			last_opened: null,
			date_opening: null,
			totals: {
				total_rent: '0.00',
				total_items: '0.00',
				total_income: '0.00',
				final_balance_frt: '0.00',
			},
			finalBalance: 0,
			loaded: false,
		};
	},
	components: {
		OpenCashForm,
		TrxForm,
	},
	
	async created() {
		await this.$eventHub.$on('reloadData', (resource) => {
			// this.loaded = false
			if (this.resource == resource) {
				this.getOpenedCash();
			}
		});

		await this.getOpenedCash();
	},
	methods: {
		async getOpenedCash() {
			await this.$http.get(`${this.resource}/opened-cash`).then((response) => {
				if (response.data.success) {
					this.cash = response.data.cash;
					this.last_opened = response.data.last_opened;

					if (this.cash) {
						this.date_opening = dayjs(this.cash.date_opening).format('DD/MM/YYYY');
						this.getRecords();
					}

					if (this.last_opened) {
						this.date_opening = dayjs(this.last_opened.date_opening).format(
							'DD/MM/YYYY'
						);
						this.getRecords();
					}
				}

				this.loaded = true;
			});
		},
		clickOpenCash() {
			this.showDialog = true;
		},
		clickCloseCash() {
			this.confirm().then(() => {
				let recordId = this.last_opened ? this.last_opened.id : this.cash.id;
				this.$http
					.post(`${this.resource}/close/${recordId}`)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							location.reload();
							// this.$eventHub.$emit('reloadData', this.resource)
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						this.$message.error(error.response.data.message);
						// console.log(error.response.data.message);
					});
			});
		},
		getRecords() {
			if (this.cash || this.last_opened) {
				let recordId = this.last_opened ? this.last_opened.id : this.cash.id;
				this.$http.get(`/${this.resource}/transactions/${recordId}`).then((response) => {
					this.totals = response.data.totals;
					this.record_rents = response.data.record_rents;
					this.record_items = response.data.record_items;
				});
			}
		},
		openTrxDialog() {
			this.recordId = this.cash.id;
			this.finalBalance = this.totals.final_balance;
			this.showTrxFormDialog = true;
		},
	},
};
</script>
